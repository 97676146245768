import { Navigate, RouteProps } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import { appRoutes } from 'routes/appRoutes';

const PrivateRoute = ({ ...props }: RouteProps): JSX.Element => {
  const {
    keycloak: { authenticated },
  } = useKeycloak();

  if (authenticated) {
    return props.element as unknown as JSX.Element;
  }

  return (
    <Navigate replace to={appRoutes.login} state={{ referer: window.location.href }} />
  );
};

export { PrivateRoute };
