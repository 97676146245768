import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import { Box } from '@mui/system';

type IntlMessage = { id: string; values?: Record<string, any> };

interface Props extends PropsWithChildren {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelHandler?: () => void;
  onConfirmHandler: () => void;
  title: IntlMessage;
  description: IntlMessage;
  confirmText: IntlMessage;
  cancelTextId?: string;
  isDeletion?: boolean;
  isSubmitDisabled?: boolean;
}

export const SimpleControlledDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onCancelHandler,
  onConfirmHandler,
  title,
  description,
  confirmText,
  children,
  cancelTextId = 'cancel',
  isDeletion = false,
  isSubmitDisabled = false,
}) => {
  const handleClose = (_: React.MouseEvent, reason: string) => {
    if (reason === 'backdropClick') {
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ minWidth: 485, padding: '32px' }}>
        <Typography variant="h1" mb={2}>
          <FormattedMessage {...title} />
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage {...description} />
        </Typography>
        <Box>{children}</Box>
        <Box mt={4} display="flex" gap={2}>
          <Button
            variant="contained"
            color={isDeletion ? 'error' : 'primary'}
            onClick={onConfirmHandler}
            disabled={isSubmitDisabled}
          >
            <FormattedMessage {...confirmText} />
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
              onCancelHandler?.();
            }}
          >
            <FormattedMessage id={cancelTextId} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
