import React from 'react';

import Box from '@mui/material/Box';

import { styles } from './styles';

const ContentLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => <Box sx={styles.containerLayout}>{children}</Box>;
export { ContentLayout };
