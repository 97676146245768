import { CONTENT_MAX_WIDTH } from './constants';

export const styles = {
  root: {
    maxWidth: CONTENT_MAX_WIDTH,
    margin: '0 auto',
    width: '100%',
    padding: '0 48px',
  },
};
