import React from 'react';

import { Box, BoxProps } from '@mui/material';

import { themeColors } from 'theme';

export const ContentWrapper: React.FC<React.PropsWithChildren & BoxProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: themeColors.white,
        padding: 4,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
