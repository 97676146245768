import { Sort } from 'api';

export const getSortToUrlParam = <T>(sort: Sort<T>) => `${sort.field}:${sort.order}`;

export const convertParamsToUrl = (params: Record<string, any>) => {
  const convertedParams = {} as Record<string, string>;
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined || params[key] === null) {
      return;
    }
    convertedParams[key] = params[key];
  });

  return convertedParams;
};
