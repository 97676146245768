import { API, endpoints, MediaAsset, PagedResponse } from 'api';

import { convertParamsToUrl } from 'utils/api';

export const getXandrAdvertiser = async ({
  id,
  ...params
}: {
  id: string;
  country: string;
}) => {
  const convertedParams = convertParamsToUrl(params);
  const { data } = await API.get<any>(endpoints.xandrAdvertiser({ id }), {
    params: convertedParams,
  });

  return data;
};
