import Keycloak, { KeycloakConfig } from 'keycloak-js';

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOCK_URL,
  realm: process.env.REACT_APP_KEYCLOCK_REALM,
  clientId: process.env.REACT_APP_KEYCLOCK_CLIENT_ID,
} as KeycloakConfig;

export const keycloakScope = process.env.REACT_APP_KEYCLOCK_SCOPE;

export const keycloakInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false,
};

export const keycloakInstance = new Keycloak(keycloakConfig);
