import React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import { Icon } from 'components/icon';
import { OffsetContainer } from 'components/offset-container';
import { theme } from 'theme';

import { LOGO_WIDTH } from './constants';
import { HelpLinks } from './help-links';
import { styles } from './styles';

const Footer: React.FC = () => (
  <Box component="footer" sx={styles.root}>
    <OffsetContainer>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Icon
            name="dpg-footer-logo"
            width={LOGO_WIDTH}
            color={theme.palette.common.white}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HelpLinks />
        </Grid>
      </Grid>
    </OffsetContainer>
  </Box>
);

export { Footer };
