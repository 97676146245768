import React from 'react';

import { useKeycloak } from '@react-keycloak/web';

import { AppContextType, User } from './types';

export const AppContext = React.createContext<AppContextType>({
  user: {
    given_name: '',
    family_name: '',
    email: '',
    name: '',
  },
  token: '',
});

interface Props {
  children: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const {
    keycloak: { idToken = '', idTokenParsed },
  } = useKeycloak();

  return (
    <AppContext.Provider
      value={{
        user: idTokenParsed as unknown as User,
        token: idToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
