import { themeColors } from 'theme/themeColors';

const { purplePrimary, pink, redPrimary, yellowPrimary } = themeColors;

export const styles = {
  header: {
    backgroundColor: themeColors.white,
    borderImage: `
      linear-gradient(
      to right, 
      ${purplePrimary} 30%, 
      ${pink} 30%, 
      ${pink} 45%, 
      ${yellowPrimary} 45%, 
      ${yellowPrimary} 75%, 
      ${redPrimary} 75%);
      `,
    borderImageSlice: 1,
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    minHeight: 110,
  },
  content: {
    flex: 'auto',
  },
  mainContainer: {
    backgroundColor: themeColors.grey20,
    overflowX: 'clip',
    minWidth: 1368,
  },
};
