type Params = Record<string, string | number>;
type Route = Record<string, string>;
type RouteFn = (params?: Params) => string;

const getRouter = (path: string, params: Params) => {
  return Object.keys(params).reduce((endpoint: string, key: string) => {
    return endpoint.replace(':' + key, params[key] as string);
  }, path);
};

export const createAppRoutes = <T extends Route, K extends keyof T>(
  endpoints: T,
  defaults: Params = {}
): Record<K, RouteFn> => {
  return Object.keys(endpoints).reduce(
    (apis: Record<string, RouteFn>, pathKey: string) => {
      apis[pathKey] = (params: Params = {}) =>
        getRouter(endpoints[pathKey], { ...defaults, ...params });

      return apis;
    },
    {}
  ) as Record<K, RouteFn>;
};
