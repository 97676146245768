import { snakeCaseToKebabCase } from 'utils';

import { AdFormat } from 'api/types';

const routesByAdType = Object.values(AdFormat).reduce((acc, format) => {
  acc[format] = {
    create: `/${snakeCaseToKebabCase(format)}/create`,
    edit: `/${snakeCaseToKebabCase(format)}/:id`,
  };
  return acc;
}, {} as Record<AdFormat, { create: string; edit: string }>);

export const appRoutes = {
  home: '/',
  overview: '/overview',
  selectAdvertiser: '/select-advertiser',
  bulkUpload: '/bulk-upload',
  login: '/login',

  ...routesByAdType,
};

export const getAppRouteFromCreativeFormat = (
  creativeFormat: AdFormat
): { createUrl: string; updateUrl: string } => {
  return {
    createUrl: appRoutes[creativeFormat].create,
    updateUrl: appRoutes[creativeFormat].edit,
  };
};

export const COUNTRY_LS_KEY = 'ls_design_country';
export const CAMPAIGN_ITEM_ID_LS_KEY = 'ls_design_campaign_item_id';
