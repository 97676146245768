import { queryKeys } from 'api';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { appRoutes } from 'routes/appRoutes';

interface RedirectProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  redirectRoute: string;
}

export const useRedirect = ({ setIsLoading, redirectRoute }: RedirectProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [search, _] = useSearchParams();
  const onCancel = () => {
    navigate(`${appRoutes.overview}?${search}`);
  };

  const onSave = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.campaignData] });
    navigate(appRoutes.overview + `?${search}`);
  };

  const onDuplicate = (id: string) => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.campaignData] });

    if (redirectRoute) {
      navigate(redirectRoute.replace(':id', id) + `?${search}`);

      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } else {
      navigate(`${appRoutes.overview}?${search}`);
    }
  };

  return { onCancel, onSave, onDuplicate };
};
