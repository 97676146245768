import { Radios as MuiRadios, RadioData } from 'mui-rff';

import { FormControl, FormControlProps, RadioGroup } from '@mui/material';

interface Props {
  name: string;
  options: RadioData[];
  direction?: 'row' | 'column';
}

export const Radios: React.FC<Props> = ({ name, options, direction = 'row' }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup sx={{}}>
        <MuiRadios
          name={name}
          size="small"
          formControlProps={{
            sx: {
              '& .MuiFormGroup-root': {
                display: 'flex',
                justifyContent: 'flex-start',
                ...(direction === 'column' && {
                  flexDirection: 'column',
                }),
                '& .MuiFormControlLabel-root': {
                  marginLeft: '0',
                },
                ...(direction === 'row' && {
                  gap: 6,
                }),
                '& .MuiRadio-root': {
                  paddingLeft: 0,
                  marginLeft: '0 !important',

                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                },
              },
            },
          }}
          data={options}
        />
      </RadioGroup>
    </FormControl>
  );
};
