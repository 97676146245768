import { Loader } from 'components';
import { keycloakInitOptions, keycloakInstance } from 'keycloak/keycloakConfig';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';

import { Box } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { defaultLanguage, translations } from 'localization/localization';
import { Locales } from 'localization/types';

import App from './App';

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_TOKEN || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReactKeycloakProvider
    authClient={keycloakInstance}
    LoadingComponent={
      <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <Loader width={150} />
      </Box>
    }
    initOptions={keycloakInitOptions}
  >
    <IntlProvider
      locale={Locales.EN}
      defaultLocale={defaultLanguage}
      messages={translations[defaultLanguage]}
    >
      <Router>
        <Suspense fallback={<Loader fullWidth width={100} />}>
          <SnackbarProvider
            maxSnack={8}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <App />
          </SnackbarProvider>
        </Suspense>
      </Router>
    </IntlProvider>
  </ReactKeycloakProvider>
);
