import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Breadcrumbs } from 'components/breadcrumbs';
import { UserLogoDropdown } from 'components/menu/UserLogoDropdown';

import { styles } from './styles';
import { MenuItem } from './types';

interface Props {
  menuItems?: MenuItem[];
}

const MenuComponent: React.FC<Props> = ({ menuItems }) => {
  return (
    <>
      <Grid
        item
        container
        xs
        alignItems="center"
        sx={[styles.breadcrumbsContainer, styles.root]}
      >
        <Box sx={styles.breadcrumbs}>
          <Breadcrumbs />
        </Box>
      </Grid>
      <Grid item sx={styles.root}>
        <Grid container height="100%" alignItems="center">
          {menuItems?.map(({ path, translationId, icon, title }) => {
            if (translationId) {
              return (
                <Grid key={translationId} item>
                  <NavLink to={path} title={title} style={{ pointerEvents: 'none' }}>
                    <Typography variant="h3">
                      <FormattedMessage id={`${translationId}`} />
                    </Typography>
                  </NavLink>
                </Grid>
              );
            }

            return <></>;
          })}
          <UserLogoDropdown />
        </Grid>
      </Grid>
    </>
  );
};

const Menu = React.memo(MenuComponent);

export { Menu };
