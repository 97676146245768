import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import { LinkType } from 'components/external-link/ExternalLink';

import { ExternalLink } from '../../external-link';
import { styles } from '../styles';

type Props = {
  isExternal?: boolean;
  href: string;
  translationId: string;
  isVisible?: boolean;
};

const HelpLink: React.FC<Props> = ({
  isExternal = true,
  href,
  translationId,
  isVisible = true,
}) => {
  const LinkMessage = <FormattedMessage id={`footer.help_links.${translationId}`} />;

  return (
    <Typography
      variant="body1"
      sx={styles.text}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      {isExternal ? (
        <ExternalLink href={href} type={LinkType.Footer}>
          {LinkMessage}
        </ExternalLink>
      ) : (
        <Link to={href}>{LinkMessage}</Link>
      )}
    </Typography>
  );
};

export { HelpLink };
