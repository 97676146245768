import { SxProps } from '@mui/material';

export const styles: {
  wrapper: SxProps;
  infoModal: SxProps;
  padding: SxProps;
  flex: SxProps;
  titleWrapper: SxProps;
} = {
  wrapper: {
    position: 'relative',
    display: 'flex',
  },
  infoModal: {
    display: 'flex',
    cursor: 'pointer',
  },
  padding: {
    paddingTop: '15px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
  },
};
