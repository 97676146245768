import { API } from 'api';
import { useRef } from 'react';

export const useUserToken = (token: string) => {
  const initialLoad = useRef(true);

  if (initialLoad.current) {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    initialLoad.current = false;
  }
};
