import en from './en.json';
import { Locales, TranslationsMap } from './types';

const localization = process.env.REACT_APP_LOCALIZATION;

const defaultLanguage = (localization as Locales) || Locales.EN;

const translations: TranslationsMap = {
  en,
};

export { translations, defaultLanguage };
