import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export const getEllipsisMenuItems = (length: number) => {
  const maxWidthMap = {
    sm: '',
    md: '',
    lg: '',
    xl: '',
  };

  if (length === 2) {
    maxWidthMap.sm = '70%';
    maxWidthMap.md = '100%';
    maxWidthMap.lg = '100%';
    maxWidthMap.xl = '100%';
  } else if (length === 3) {
    maxWidthMap.sm = '40%';
    maxWidthMap.md = '50%';
    maxWidthMap.lg = '50%';
    maxWidthMap.xl = '50%';
  } else if (length === 4) {
    maxWidthMap.sm = '20%';
    maxWidthMap.md = '30%';
    maxWidthMap.lg = '30%';
    maxWidthMap.xl = '30%';
  }

  return {
    '& .MuiBreadcrumbs-li': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      minWidth: 25,
      [theme.breakpoints.up('sm')]: {
        maxWidth: maxWidthMap.sm,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: maxWidthMap.md,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: maxWidthMap.lg,
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: maxWidthMap.xl,
      },
    },
    ...(length === 1
      ? {
          '& .MuiBreadcrumbs-li': {
            width: '100%',
          },
        }
      : {}),
  };
};

export const styles = {
  breadcrumb: {
    color: themeColors.black,
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
    },
  },
  linkItem: {
    lineHeight: 1,
    display: 'flex !important',
    alignItems: 'center',
    padding: '15px 0px !important',
    '&:hover svg': {
      color: themeColors.pink + '!important',
    },
  },
  linkIcon: {
    color: themeColors.black + '!important',
    '& + *': {
      marginLeft: '10px',
    },
    '&.settings svg': {
      width: 24,
      height: 24,
      minWidth: 20,
      minHieght: 20,
    },
  },
  lastItem: {
    color: themeColors.pink + '!important',
    display: 'inline-block',
    padding: '35px 0px',
  },
};

type LinkProps = {
  islastitem: string;
};

export const StyledLink = styled(Link)<LinkProps>(
  {
    ...styles.linkItem,
  },
  ({ islastitem }) =>
    islastitem === 'true' && {
      color: themeColors.pink + '!important',
      display: 'inline-block',
      padding: '35px 0px',
    }
);
