import { SxProps } from '@mui/system';

export const styles: {
  containerLayout: SxProps;
} = {
  containerLayout: {
    margin: '0 auto',
    position: 'relative',
  },
};
