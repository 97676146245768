export interface ThemeColors {
  black: string;
  lightBlack: string;
  white: string;
  purplePrimary: string;
  purpleHover: string;
  purple80: string;
  purple60: string;
  purple40: string;
  purple20: string;
  greyPrimary: string;
  grey120: string;
  grey60: string;
  grey40: string;
  grey30: string;
  grey20: string;
  grey10: string;
  pink: string;
  pinkHover: string;
  pink40: string;
  pink20: string;
  pink10: string;
  yellowPrimary: string;
  yellow40: string;
  yellow20: string;
  redPrimary: string;
  red: string;
  redHover: string;
  red20: string;
  greenPrimary: string;
  green20: string;
  info: string;
}

export const themeColors: ThemeColors = {
  black: '#000000',
  lightBlack: '#232323',
  white: '#ffffff',
  purplePrimary: '#783C96',
  purpleHover: '#412052',
  purple80: '#9363AB',
  purple60: '#AE8AC0',
  purple40: '#D3BFDD',
  purple20: '#F1EAF4',
  greyPrimary: '#A5A5A5',
  grey120: '#757575',
  grey60: '#C9C9C9',
  grey40: '#DBDBDB',
  grey30: '#E4E4E4',
  grey20: '#EFEFEF',
  grey10: '#f6f6f6',
  pink: '#d23278',
  pinkHover: '#942757',
  pink40: '#EDADC9',
  pink20: '#F6D6E4',
  pink10: '#FAEAF1',
  yellowPrimary: '#fabb22',
  yellow40: '#FDE4A7',
  yellow20: '#fff5de',
  redPrimary: '#e6463c',
  red: '#D64036',
  redHover: '#AB342D',
  red20: '#fce4e2',
  greenPrimary: '#73C800',
  green20: '#e3f4cc',
  info: 'rgba(255,184,28, 0.2)',
};
