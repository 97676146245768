import { SxProps } from '@mui/system';

import { themeColors } from 'theme/themeColors';

export const styles = (isIconHoverAble: boolean): SxProps => ({
  maxWidth: '100%',
  fontSize: 16,
  display: 'inline-flex',
  alignItems: 'center',
  fontFamily: 'Proxima Nova Bold',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: themeColors.purpleHover,
    '& svg': {
      fill: themeColors.purpleHover,
      ...(isIconHoverAble && {
        '& g': {
          fill: themeColors.purpleHover,
        },
        '& path': {
          fill: themeColors.purpleHover,
        },
      }),
    },
  },
});
