import React, { ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridRowTreeNodeConfig } from '@mui/x-data-grid';
import {
  DataGridProProps,
  GridRowId,
  DataGridPro as MuiDataGrid,
} from '@mui/x-data-grid-pro';

import { CreativesGridHeaderActions } from './CreativesGridHeaderActions';
import { LoadingOverlay } from './loading-overlay';
import { AscendingIcon } from './SortColumn/AscendingIcon';
import { DescendingIcon } from './SortColumn/DescendingIcon';
import { NotSortedIcon } from './SortColumn/NotSortedIcon';
import { useStyles } from './styles';

interface Props extends DataGridProProps {
  gridTitleId?: string;
  scrollToAfterUpdate?: 'header' | 'top';
  hasFilters?: boolean;
  SearchComponent?: ReactNode;
  selectedRows?: string[];
  onDeselectAll: () => void;
  clearFilters?(): void;
  setSelectedRows?(selectedRows: string[]): void;
  setSelectionModel?(selectedRows: string[]): void;
  onBulkDeletion(ids: string[]): void;
  onBulkPublish(ids: string[]): void;
}

const DataGrid: React.FC<Props> = ({
  scrollToAfterUpdate = 'header',
  gridTitleId,
  SearchComponent,
  ...props
}) => {
  const { paginationModel, selectedRows, onBulkDeletion, onBulkPublish, onDeselectAll } =
    props;
  const ref = useRef<any>(null);
  const expansionLookup = React.useRef<Record<GridRowId, boolean>>({});
  const styles = useStyles();

  React.useEffect(() => {
    if (props?.apiRef && expansionLookup.current) {
      props?.apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
        expansionLookup.current[node.id] = node.childrenExpanded as boolean;
      });
    }
  }, [props?.apiRef]);

  const isGroupExpandedByDefault = React.useCallback(
    (node: GridRowTreeNodeConfig) => {
      return !!expansionLookup.current[node.id];
    },
    [expansionLookup]
  );

  const newProps: DataGridProProps = {
    hideFooter: true,
    sx: styles,
    rowHeight: 72,
    columnHeaderHeight: 60,
    disableColumnMenu: true,
    hideFooterSelectedRowCount: true,
    columnVisibilityModel: { id: false },

    ...props,
    slots: {
      loadingOverlay: () => <LoadingOverlay />,
      noRowsOverlay: () => null,
      columnSortedAscendingIcon: AscendingIcon,
      columnSortedDescendingIcon: DescendingIcon,
      columnUnsortedIcon: NotSortedIcon,
      ...props.slots,
    },
    paginationModel,
    density: 'standard',
    rowCount: props.rowCount || 0,
    isGroupExpandedByDefault,
  };

  return (
    <Box sx={styles.wrapper}>
      {gridTitleId || SearchComponent ? (
        <Box sx={styles.titleAndSearch}>
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            {gridTitleId && !selectedRows?.length && (
              <Box sx={styles.gridTitle}>
                <Typography variant="h3">
                  <FormattedMessage id={gridTitleId} />
                </Typography>
                <Typography sx={styles.gridCount}>
                  <FormattedMessage
                    id="grid.count.value"
                    values={{ count: props.rowCount }}
                  />
                </Typography>
              </Box>
            )}
            <CreativesGridHeaderActions
              rows={selectedRows}
              onDelete={onBulkDeletion}
              onPublish={onBulkPublish}
              onDeselectAll={onDeselectAll}
            />
            <Box display="flex">{SearchComponent}</Box>
          </Box>
        </Box>
      ) : (
        <Box pt={2} />
      )}
      <MuiDataGrid {...newProps} ref={ref} />
    </Box>
  );
};

export { DataGrid };
