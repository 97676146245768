import { AD_STATUS, AdFormat, COUNTRY } from 'api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRoutes } from 'routes';

import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

export const ROW_HEIGHT = 73;

export const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const getIsCreativeSet = (id: string) => uuidRegex.test(String(id));

export const useHandleEdit = () => {
  const [search, _] = useSearchParams();
  const navigate = useNavigate();
  const handleEdit = (type: AdFormat, id: string) => {
    navigate(appRoutes[type].edit.replace(':id', id) + `?${search}`);
  };
  return { handleEdit };
};

export const allowedFormatsForBulkUpload = [
  AdFormat.SEAMLESS_IMAGE,
  AdFormat.SEAMLESS_IMAGE_L,
  AdFormat.SEAMLESS_IMAGE_XL,
  AdFormat.NATIVE,
  AdFormat.SEAMLESS_CAROUSEL,
  AdFormat.SEAMLESS_VIDEO,
  AdFormat.SEAMLESS_VIDEO_L,
  AdFormat.SEAMLESS_VIDEO_XL,
];

export const pageSizeOptions = [10, 50, 100];

export const getRowClassName = (params: GridRowClassNameParams<any>): string => {
  const isCreativeSetChildClassName =
    params.row.hierarchy?.length > 1 ? 'CreativeSetRow' : '';
  const isLastCreativeSetChild = params.row.isLastCreativeSetChild ? 'last-child' : '';
  return `${isCreativeSetChildClassName} ${isLastCreativeSetChild}`;
};

export const getCreativesStates = (creativesList: { state: AD_STATUS }[]): boolean => {
  if (!creativesList?.length) {
    return false;
  }
  const uniqueStatusesOfCreativeList = [
    ...new Set(creativesList?.map((creative) => creative.state)),
  ];

  return uniqueStatusesOfCreativeList?.length > 1 ?? false;
};

export const newCreativeMenuItems: Record<COUNTRY, any> = {
  [COUNTRY.NL]: [
    {
      textId: 'menu_item.title.seamless_and_native',
      children: [
        // seamless image
        {
          textId: 'menu_item.creative_type.seamless_image',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE,
        },
        {
          textId: 'menu_item.creative_type.seamless_image_l',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE_L].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE_L,
        },
        {
          textId: 'menu_item.creative_type.seamless_image_xl',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE_XL].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE_XL,
        },
        {
          textId: 'menu_item.creative_type.seamless_carousel',
          link: appRoutes[AdFormat.SEAMLESS_CAROUSEL].create,
          creativeFormat: AdFormat.SEAMLESS_CAROUSEL,
        },
        // seamless image leadgen
        {
          textId: 'menu_item.creative_type.leadgen_seamless_image',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_IMAGE].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_IMAGE,
        },
        {
          textId: 'menu_item.creative_type.leadgen_seamless_image_l',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_IMAGE_L].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_IMAGE_L,
        },
        {
          textId: 'menu_item.creative_type.leadgen_seamless_image_xl',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_IMAGE_XL].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_IMAGE_XL,
        },
        // native
        {
          textId: 'menu_item.creative_type.native',
          link: appRoutes[AdFormat.NATIVE].create,
          creativeFormat: AdFormat.NATIVE,
        },
        {
          textId: 'menu_item.creative_type.seamless_image_cross_device',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE_CROSS_DEVICE].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE_CROSS_DEVICE,
        },
        {
          textId: 'menu_item.creative_type.seamless_image_mini',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE_MINI].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE_MINI,
        },

        // tweakers
        {
          textId: 'menu_item.creative_type.tweakers_featured_product',
          link: appRoutes[AdFormat.TWEAKERS_FEATURED_PRODUCT].create,
          creativeFormat: AdFormat.TWEAKERS_FEATURED_PRODUCT,
        },
        {
          textId: 'menu_item.creative_type.tweakers_featured_shop',
          link: appRoutes[AdFormat.TWEAKERS_FEATURED_SHOP].create,
          creativeFormat: AdFormat.TWEAKERS_FEATURED_SHOP,
        },
        {
          textId: 'menu_item.creative_type.tweakers_advertorial',
          link: appRoutes[AdFormat.TWEAKERS_ADVERTORIAL].create,
          creativeFormat: AdFormat.TWEAKERS_ADVERTORIAL,
        },
        // listing ad
        {
          textId: 'menu_item.creative_type.listing_ad',
          link: appRoutes[AdFormat.LISTING_AD].create,
          creativeFormat: AdFormat.LISTING_AD,
        },
        // deals
        {
          textId: 'menu_item.creative_type.deals',
          link: appRoutes[AdFormat.DEALS].create,
          creativeFormat: AdFormat.DEALS,
        },
      ],
    },
    {
      textId: 'menu_item.title.video',
      children: [
        // seamless video
        {
          textId: 'menu_item.creative_type.seamless_video',
          link: appRoutes[AdFormat.SEAMLESS_VIDEO].create,
          creativeFormat: AdFormat.SEAMLESS_VIDEO,
        },
        {
          textId: 'menu_item.creative_type.seamless_video_l',
          link: appRoutes[AdFormat.SEAMLESS_VIDEO_L].create,
          creativeFormat: AdFormat.SEAMLESS_VIDEO_L,
        },
        {
          textId: 'menu_item.creative_type.seamless_video_xl',
          link: appRoutes[AdFormat.SEAMLESS_VIDEO_XL].create,
          creativeFormat: AdFormat.SEAMLESS_VIDEO_XL,
        },
        // seamless video leadgen
        {
          textId: 'menu_item.creative_type.leadgen_seamless_video',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_VIDEO].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_VIDEO,
        },
        {
          textId: 'menu_item.creative_type.leadgen_seamless_video_l',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_VIDEO_L].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_VIDEO_L,
        },
        {
          textId: 'menu_item.creative_type.leadgen_seamless_video_xl',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_VIDEO_XL].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_VIDEO_XL,
        },
      ],
    },
    {
      textId: 'menu_item.title.brandedContent',
      children: [
        // branded content
        {
          textId: 'menu_item.creative_type.branded_content',
          link: appRoutes[AdFormat.BRANDED_CONTENT].create,
          creativeFormat: AdFormat.BRANDED_CONTENT,
        },
        {
          textId: 'menu_item.creative_type.branded_content_adr',
          link: appRoutes[AdFormat.BRANDED_CONTENT_ADR].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_ADR,
        },
        {
          textId: 'menu_item.creative_type.branded_content_nu_nl',
          link: appRoutes[AdFormat.BRANDED_CONTENT_NU_NL].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_NU_NL,
        },
        {
          textId: 'menu_item.creative_type.branded_content_vtp',
          link: appRoutes[AdFormat.BRANDED_CONTENT_VTP].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_VTP,
        },
        {
          textId: 'menu_item.creative_type.branded_content_lmf',
          link: appRoutes[AdFormat.BRANDED_CONTENT_LMF].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_LMF,
        },
        {
          textId: 'menu_item.creative_type.branded_content_video',
          link: appRoutes[AdFormat.BRANDED_CONTENT_VIDEO].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_VIDEO,
        },
      ],
    },
  ],
  [COUNTRY.BE]: [
    {
      textId: 'menu_item.title.seamless_and_native',
      children: [
        // seamless image
        {
          textId: 'menu_item.creative_type.belgium.seamless_image',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE,
        },
        {
          textId: 'menu_item.creative_type.belgium.seamless_image_xl',
          link: appRoutes[AdFormat.SEAMLESS_IMAGE_XL].create,
          creativeFormat: AdFormat.SEAMLESS_IMAGE_XL,
        },
        {
          textId: 'menu_item.creative_type.seamless_carousel',
          link: appRoutes[AdFormat.SEAMLESS_CAROUSEL].create,
          creativeFormat: AdFormat.SEAMLESS_CAROUSEL,
        },
        // seamless image leadgen
        {
          textId: 'menu_item.creative_type.belgium.leadgen_seamless_image',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_IMAGE].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_IMAGE,
        },
        {
          textId: 'menu_item.creative_type.belgium.leadgen_seamless_image_xl',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_IMAGE_XL].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_IMAGE_XL,
        },
        // native
        {
          textId: 'menu_item.creative_type.native',
          link: appRoutes[AdFormat.NATIVE].create,
          creativeFormat: AdFormat.NATIVE,
        },
        // adoptimize
        {
          textId: 'menu_item.creative_type.adoptimize_clicks',
          link: appRoutes[AdFormat.ADOPTIMIZE_CLICKS].create,
          creativeFormat: AdFormat.ADOPTIMIZE_CLICKS,
        },
      ],
    },
    {
      textId: 'menu_item.title.video',
      children: [
        // seamless video
        {
          textId: 'menu_item.creative_type.belgium.seamless_video',
          link: appRoutes[AdFormat.SEAMLESS_VIDEO].create,
          creativeFormat: AdFormat.SEAMLESS_VIDEO,
        },
        {
          textId: 'menu_item.creative_type.belgium.seamless_video_xl',
          link: appRoutes[AdFormat.SEAMLESS_VIDEO_XL].create,
          creativeFormat: AdFormat.SEAMLESS_VIDEO_XL,
        },
        // seamless video leadgen
        {
          textId: 'menu_item.creative_type.belgium.leadgen_seamless_video',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_VIDEO].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_VIDEO,
        },
        {
          textId: 'menu_item.creative_type.belgium.leadgen_seamless_video_xl',
          link: appRoutes[AdFormat.LEADGEN_SEAMLESS_VIDEO_XL].create,
          creativeFormat: AdFormat.LEADGEN_SEAMLESS_VIDEO_XL,
        },
      ],
    },
    {
      textId: 'menu_item.title.brandedContent',
      children: [
        // branded content
        {
          textId: 'menu_item.creative_type.branded_content',
          link: appRoutes[AdFormat.BRANDED_CONTENT].create,
          creativeFormat: AdFormat.BRANDED_CONTENT,
        },
        {
          textId: 'menu_item.creative_type.branded_content_hln',
          link: appRoutes[AdFormat.BRANDED_CONTENT_HLN].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_HLN,
        },
        {
          textId: 'menu_item.creative_type.branded_content_de_morgen',
          link: appRoutes[AdFormat.BRANDED_CONTENT_DE_MORGEN].create,
          creativeFormat: AdFormat.BRANDED_CONTENT_DE_MORGEN,
        },
      ],
    },
  ],
};
