import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Divider, SxProps, Typography } from '@mui/material';

import { themeColors } from 'theme';

interface Props extends React.PropsWithChildren {
  titleId: string;
  withDivider?: boolean;
  sx?: SxProps;
}

export const FieldSection: React.FC<Props> = ({ children, titleId, withDivider, sx }) => {
  return (
    <Box sx={sx}>
      <Typography mb={4} variant="h2" color={themeColors.grey120}>
        <FormattedMessage id={titleId} />
      </Typography>
      <Box pb={withDivider ? 4 : 0}>{children}</Box>
      {withDivider && <Divider />}
    </Box>
  );
};
