import { AdFormat } from 'api';
import { snakeCaseToKebabCase } from 'utils';

import { appRoutes } from './appRoutes';

const routesByAdType = Object.values(AdFormat).reduce((acc, format) => {
  acc.push(
    `/${snakeCaseToKebabCase(format)}/create`,
    `/${snakeCaseToKebabCase(format)}/:id`
  );
  return acc;
}, [] as string[]);

export const viewRoutes: { path: string; isCreate?: boolean; type?: 'BULK' }[] = [
  ...routesByAdType.map((route) => ({ path: route })),
  {
    path: appRoutes.bulkUpload,
    type: 'BULK',
  },
];
