import React from 'react';

import { Chip, ChipProps } from '@mui/material';

import { themeColors } from 'theme';

export const CreativeSetChip: React.FC<ChipProps> = (props) => {
  return (
    <Chip
      {...props}
      sx={{
        height: 24,
        minWidth: 24,
        width: 'max-content',
        color: themeColors.pink,
        backgroundColor: '#faeaf1',
        fontSize: 14,
        '& .MuiChip-label': {
          padding: '0 6px',
        },
      }}
    />
  );
};
