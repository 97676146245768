import { keycloakScope } from 'keycloak/keycloakConfig';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

const Login: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { state } = useLocation();

  useEffect(() => {
    keycloak?.login({
      scope: keycloakScope,
      redirectUri: state?.referer || window.location.origin,
    });
  }, [keycloak, state]);

  return null;
};

export { Login };
