import React from 'react';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { getStyles } from 'components/loader/styles';

type Props = {
  width: number;
  showProgress?: boolean;
  progress?: number;
  fullWidth?: boolean;
};

const Loader: React.FC<Props> = ({
  width,
  showProgress,
  progress,
  fullWidth = false,
}) => {
  const styles = getStyles(width);
  return (
    <Box display="grid" flexDirection="column" justifyItems="center">
      <Box
        sx={{
          width,
          height: width / 1.2,
          pt: `${width / 10}px`,
          ...(fullWidth && { ...styles.wrapper }),
        }}
      >
        <Box sx={styles.box}>
          <Box sx={styles.purple} />
          <Box sx={styles.rose} />
          <Box sx={styles.orangered} />
          <Box sx={styles.gold} />
        </Box>
      </Box>
      {showProgress && (
        <Typography
          variant="h1"
          component="div"
          color="primary"
        >{`${progress}%`}</Typography>
      )}
    </Box>
  );
};

export { Loader };
