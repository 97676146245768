import { CampaignDataPayload, COUNTRY, queryKeys } from 'api';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUrlSearchParams } from 'utils';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CAMPAIGN_ITEM_ID_LS_KEY, COUNTRY_LS_KEY } from 'routes/appRoutes';

import { getCampaignData } from './queries';

export const useGetCampaignData = (enabled = true) => {
  const cache = useQueryClient();

  const cachedData = cache.getQueryData([queryKeys.campaignData]);
  const { country, campaignItemId } = useUrlSearchParams();

  const params: CampaignDataPayload = {
    country: country! as COUNTRY,
    campaignItemId: campaignItemId!,
  };

  return useQuery({
    queryKey: [queryKeys.campaignData],
    queryFn: () => getCampaignData(params),
    enabled: !cachedData && enabled && Boolean(campaignItemId) && Boolean(country),
  });
};
