import React from 'react';

import { Grid } from '@mui/material';

import { Dropdown } from 'components/dropdown';
import { styles } from 'components/menu/styles';

import { useMenuItems } from './useMenuItems';

const UserLogoDropdown: React.FC = () => {
  const { UserLogoElem, menuItems = [] } = useMenuItems();

  return (
    <Grid item sx={styles.grid}>
      <Dropdown
        disableAutoFocusItem
        autoWidth={true}
        anchorElem={UserLogoElem}
        menuItems={menuItems}
        themeType={'expandable'}
      />
    </Grid>
  );
};

export { UserLogoDropdown };
