import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { styles } from '../styles';

interface Props {
  userName: string;
  userEmail: string;
}

const HeadInfo: React.FC<Props> = ({ userName, userEmail }) => (
  <Box sx={styles.headWrap}>
    <Typography variant="h3">{userName}</Typography>
    <Typography variant="body1" sx={styles.emailWrap}>
      {userEmail}
    </Typography>
  </Box>
);

export default HeadInfo;
