import { alpha, createTheme } from '@mui/material/styles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { themeColors } from './themeColors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    icon: true;
  }
}

export const theme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1921,
    },
  },
  palette: {
    primary: {
      main: themeColors.purplePrimary,
    },
    secondary: {
      main: themeColors.purple20,
    },
    success: {
      main: themeColors.greenPrimary,
    },
    warning: {
      main: themeColors.redHover,
    },
    error: {
      main: themeColors.redPrimary,
      contrastText: themeColors.pink,
    },
    info: {
      main: themeColors.info,
    },
    text: {
      primary: themeColors.black,
      secondary: themeColors.grey120,
      disabled: themeColors.black,
    },
    background: {
      paper: themeColors.grey30,
      default: themeColors.white,
    },
    action: {
      disabled: themeColors.white,
      disabledBackground: themeColors.grey120,
    },
    grey: {
      600: themeColors.grey120,
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: 'Proxima Nova Regular',
    fontSize: 16,
    body1: {
      fontSize: 16,
      letterSpacing: 0,
      color: themeColors.black,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0,
      color: themeColors.greyPrimary,
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 1.5,
      color: themeColors.greyPrimary,
    },
    subtitle2: {
      fontSize: 14,
      letterSpacing: 0,
    },
    h1: {
      fontFamily: 'Proxima Nova Bold',
      fontSize: 32,
    },
    h2: {
      fontFamily: 'Proxima Nova Regular',
      fontSize: 20,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: 'Proxima Nova Bold',
      fontSize: 18,
    },
    h4: {
      fontFamily: 'Proxima Nova Bold',
      fontSize: 16,
    },
    h5: {
      fontFamily: 'Proxima Nova Bold',
      fontSize: 14,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: 10,
    },
    h6: {
      fontFamily: 'Proxima Nova Regular',
      fontSize: 16,
      color: themeColors.grey120,
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'icon' },
          style: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontFamily: 'Proxima Nova Semibold',
            minWidth: 120,
            height: 48,
            color: themeColors.purplePrimary,
            paddingLeft: 0,
            paddingRight: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              color: themeColors.purpleHover,
            },

            '& a': {
              color: themeColors.purplePrimary,
              textDecoration: 'none',
            },
            '& svg': {
              width: '24px',
              height: '24px',
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: themeColors.red,
            '&:hover': {
              color: themeColors.redHover,
            },
          },
        },
        {
          props: { color: 'error', variant: 'contained' },
          style: {
            backgroundColor: themeColors.red,
            color: themeColors.white,
            '&:hover': {
              backgroundColor: themeColors.redHover,
              color: themeColors.white,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          fontFamily: 'Proxima Nova Bold',
          borderRadius: '3px',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.Mui-disabled .MuiButton-startIcon': {
            opacity: '1 !important',
          },
        },
        outlined: {
          padding: '13px 15px 16px',
          paddingTop: 0,
          paddingBottom: 0,
          height: 48,
          backgroundColor: themeColors.white,
          border: `solid 1px ${themeColors.purplePrimary}`,
          '&.Mui-disabled': {
            color: themeColors.greyPrimary,
            border: `solid 1px ${themeColors.greyPrimary}`,
          },
          '&:hover': {
            borderColor: themeColors.purpleHover,
            color: themeColors.purpleHover,
            backgroundColor: themeColors.white,
          },
        },
        containedSecondary: {
          color: themeColors.white,
        },
        textPrimary: {
          height: 'auto',
          fontFamily: 'Proxima Nova Semibold',
          padding: '0',
          '&:hover': {
            backgroundColor: 'transparent',
            color: themeColors.purpleHover,
          },
        },
        textSecondary: {
          minWidth: 120,
          height: 48,
          paddingBottom: 0,
          color: themeColors.grey120,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& a': {
            color: themeColors.grey120,
            textDecoration: 'none',
          },
        },
        outlinedSecondary: {
          paddingLeft: 15,
          paddingRight: 15,
        },
        containedPrimary: {
          padding: '13px 15px 16px',
          height: 48,
          paddingTop: 0,
          paddingBottom: 0,
          color: themeColors.white,
          backgroundColor: themeColors.purplePrimary,
          '&.Mui-disabled': {
            background: themeColors.greyPrimary,
          },
          '&:hover': {
            backgroundColor: themeColors.purpleHover,
          },
        },
        outlinedWarning: {
          color: themeColors.red,
          borderColor: themeColors.red,
          padding: '13px 15px 16px',
          paddingTop: 0,
          paddingBottom: 0,
          height: 48,
          '&:hover': {
            borderColor: themeColors.redHover,
            color: themeColors.redHover,
            backgroundColor: themeColors.white,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          component: 'div',
          fontFamily: 'Proxima Nova Bold',
          fontSize: 14,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&.Mui-error': {
            fontFamily: 'Proxima Nova Regular',
            color: themeColors.red,
            lineHeight: 1.43,
          },
        },
        contained: {
          marginLeft: 0,
          marginTop: 8,
          marginRight: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          padding: '15px 20px',
          fontSize: 18,
        },
        filledError: {
          color: themeColors.black,
          backgroundColor: alpha(themeColors.redPrimary, 0.2),
          borderRadius: 4,
          '& .MuiAlert-icon': {
            color: themeColors.redPrimary,
          },
        },
        filledWarning: {
          backgroundColor: alpha(themeColors.yellowPrimary, 0.2),
          color: themeColors.black,
        },
        filledInfo: {
          color: themeColors.black,
          backgroundColor: themeColors.grey20,
          '& .MuiAlert-icon': {
            color: themeColors.purplePrimary,
          },
        },
        filledSuccess: {
          backgroundColor: alpha(themeColors.greenPrimary, 0.2),
          color: themeColors.black,
          '& .MuiAlert-icon': {
            color: themeColors.greenPrimary,
          },
        },
        icon: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: themeColors.black,
          fontSize: 14,
          fontFamily: 'Proxima Nova Regular',
          backgroundColor: themeColors.white,
          padding: 24,
          maxWidth: '500px',
          boxShadow: '0 4px 12px 2px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: themeColors.white,
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {},
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: themeColors.white,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: '2px',
          background: themeColors.grey20,
          borderColor: themeColors.grey20,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontFamily: 'Proxima Nova Bold',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          '& .Mui-disabled': { color: `${themeColors.greyPrimary}!important` },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        colorPrimary: {
          '&.Mui-disabled': {
            color: themeColors.greyPrimary,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
          '&.Mui-selected': {
            backgroundColor: themeColors.purplePrimary,
            color: themeColors.white,
            '&:hover': {
              backgroundColor: themeColors.purplePrimary,
            },
            '&:focus': {
              backgroundColor: themeColors.purplePrimary,
            },
            '&:active': {
              backgroundColor: themeColors.purplePrimary,
            },
          },
          '&:hover': {
            backgroundColor: themeColors.purple20,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: 16,
          minWidth: 25,
          color: themeColors.redPrimary,
          '&.Mui-selected': {
            color: themeColors.black,
            fontWeight: 'bold',
            '&.MuiPaginationItem-page:hover': {
              backgroundColor: 'transparent!important',
            },
          },
        },
        page: {
          '&:hover, &:active, &:focus': {
            backgroundColor: 'transparent',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
          '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormGroup-root[role="radiogroup"]': {
            justifyContent: 'space-between',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 'auto',
          },
        },
        expanded: {},
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 32px 32px 32px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          minHeight: 56,
          padding: '24px 32px',
          margin: 0,
          '&.Mui-expanded': {
            minHeight: 56,
            margin: 0,
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        },
        expandIconWrapper: {
          color: themeColors.black,
        },
        content: {
          margin: 0,
          height: '24px',
          alignItems: 'center',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: 0,
        },
        root: {
          borderRadius: '3px',
          minHeight: 48,
          input: {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
          '& fieldset': {
            border: `solid 1px ${themeColors.greyPrimary}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `solid 1px ${themeColors.purplePrimary}`,
          },
          '&:hover > fieldset': {
            border: `solid 1px ${themeColors.purplePrimary} !important`,
          },
          '&.Mui-error .ErrorAdornment': {
            display: 'block',
          },
          '&.Mui-disabled': {
            color: themeColors.grey120,
            background: themeColors.grey20,
            input: {
              WebkitBoxShadow: 'none!important',
            },
            '& .MuiOutlinedInput-input': {
              textFillColor: themeColors.grey120,
            },
            '& fieldset': {
              borderColor: `${themeColors.greyPrimary} !important`,
            },
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          color: themeColors.white,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: '4px',
        },
        positionStart: {
          marginRight: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          paddingRight: 16,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          '&.MuiAutocomplete-input': {
            width: '100%',
          },
        },
        popper: {
          '&.MuiAutocomplete-popperDisablePortal': {
            position: 'relative !important',
            transform: 'unset !important',
          },
        },
        listbox: {
          border: `1px solid ${themeColors.purplePrimary}`,
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: 'rgba(112, 47, 138, 0.08)',
          },
        },
        root: {
          '& .MuiOutlinedInput-root': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          '& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
            transition: 'transform 0.2s ease',
          },
          '&.Mui-expanded .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
            transform: 'rotate(180deg)',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: themeColors.grey40,
          },
        },
      },
    },
  },
});
