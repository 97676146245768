import { SimpleControlledDialog } from 'components';
import React, { Dispatch, SetStateAction } from 'react';
import { handleSelectionWordEnding } from 'utils';

interface Props {
  selectedRows: string[];
  isDeleteModalOpen: boolean;
  isPublishModalOpen: boolean;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPublishModalOpen: Dispatch<SetStateAction<boolean>>;
  onDelete(ids: string[]): void;
  onPublish(ids: string[]): void;
  onDeselectAll(): void;
}

export const CreativesGridModals: React.FC<Props> = ({
  selectedRows,
  isDeleteModalOpen,
  isPublishModalOpen,
  setIsDeleteModalOpen,
  setIsPublishModalOpen,
  onDelete,
  onPublish,
  onDeselectAll,
}) => {
  const selectedCreatives = handleSelectionWordEnding(
    selectedRows.length,
    'selected creative'
  );
  const creatives = handleSelectionWordEnding(selectedRows.length, 'creative');

  return (
    <>
      <SimpleControlledDialog
        isOpen={isPublishModalOpen}
        setIsOpen={setIsPublishModalOpen}
        title={{
          id: 'publish.creatives.question',
          values: {
            selectedCreatives: creatives,
          },
        }}
        description={{
          id: 'publish.creatives.description',
          values: {
            selectedCreatives,
          },
        }}
        confirmText={{
          id: 'publish.selected.creatives',
          values: {
            selectedCreatives: creatives,
          },
        }}
        onConfirmHandler={() => {
          onPublish(selectedRows);
          setIsPublishModalOpen(false);
          onDeselectAll();
        }}
      />
      <SimpleControlledDialog
        isDeletion
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={{
          id: 'delete.creatives.question',
          values: {
            selectedCreatives: creatives,
          },
        }}
        description={{
          id: 'delete.creatives.description',
          values: {
            selectedCreatives,
          },
        }}
        confirmText={{
          id: 'delete.selected.creatives',
          values: {
            selectedCreatives: creatives,
          },
        }}
        onConfirmHandler={() => {
          onDelete(selectedRows);
          setIsDeleteModalOpen(false);
          onDeselectAll();
        }}
      />
    </>
  );
};
