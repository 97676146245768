import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { convertSxToThemeType } from 'utils';

import { Link, LinkProps, SxProps } from '@mui/material';

import { styles } from './styles';

export enum LinkType {
  Primary = 'primary',
  Footer = 'footer',
}

const linkPropsConfig = {
  [LinkType.Footer]: {
    target: '_blank',
    rel: 'noopener noreferrer',
    color: 'secondary',
    sx: () => ({}),
  },
  [LinkType.Primary]: {
    sx: styles,
  },
};

export interface ExternalLinkProps extends LinkProps {
  href: string;
  type: LinkType;
  icon?: React.ReactElement;
  isIconHoverAble?: boolean;
}

const ExternalLink: ForwardRefExoticComponent<
  Omit<ExternalLinkProps, 'ref'> & RefAttributes<HTMLAnchorElement>
> = React.forwardRef(
  ({ children, href, type, icon, isIconHoverAble = true, ...rest }, ref) => {
    const { sx, ...props } = linkPropsConfig[type];

    return (
      <Link
        href={href}
        {...rest}
        {...props}
        ref={ref}
        sx={convertSxToThemeType([sx(isIconHoverAble), rest.sx as SxProps])}
      >
        {children}
        {icon}
      </Link>
    );
  }
);

export { ExternalLink };
