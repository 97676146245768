import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { defaultStyles } from './theme/default';
import { expandableStyles } from './theme/expandable';
import { DropdownType } from './types';

export const styles = ({ type }: { type: string }) =>
  type === DropdownType.Expandable ? expandableStyles : defaultStyles;

type LinkProps = {
  dropdowntype: string;
  disabled?: boolean;
};

export const StyledLink = styled(Link)<LinkProps>({}, ({ dropdowntype, disabled }) =>
  dropdowntype === DropdownType.Expandable
    ? { ...expandableStyles.link, ...(disabled ? expandableStyles.linkDisabled : {}) }
    : defaultStyles.link
);
