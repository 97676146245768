import { themeColors } from 'theme/themeColors';

export const styles = {
  errorIcon: {
    fill: themeColors.redPrimary,
    display: 'none',
    width: 24,
    height: 24,
  },
  root: {
    fontSize: 16,
    borderRadius: '3px',
    paddingRight: '16px',
    '&.Mui-error': {
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
    },
  },
  textFieldCounter: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
};
