import { themeColors } from 'theme/themeColors';

export const defaultStyles = {
  list: {
    '& .MuiMenu-list': {
      padding: '10px 15px',
      borderRadius: '3ox',
      boxShadow: `0 2px 10px 0 ${themeColors.greyPrimary}`,
    },
  },
  menuItem: () => ({
    color: themeColors.purplePrimary,
    padding: '10px 5px',
    fontSize: 15,
    '&:not(:last-child)': {
      borderBottom: `2px solid ${themeColors.grey20}`,
    },
    '&:hover': {
      textDecoration: 'underline',
      background: 'unset',
    },
    '&:focus': {
      background: 'unset !important',
    },
  }),
  link: {
    color: themeColors.purplePrimary,
    textDecoration: 'none',
  },
  menuItemContainer: {},
};
