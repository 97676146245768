import React from 'react';

import Box from '@mui/material/Box';

import { styles } from './styles';

interface Props {
  children: React.ReactNode;
}

export const OffsetContainer: React.FC<Props> = ({ children }) => (
  <Box sx={styles.root}>{children}</Box>
);
