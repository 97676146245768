import { COUNTRY } from 'api';
import { useSearchParams } from 'react-router-dom';

export const useUrlSearchParams = () => {
  const [searchData, _] = useSearchParams();
  // standalone flow country
  const standaloneCountry = searchData.get('country');
  // standalone flow country
  const advendioCountry = searchData.get('source');
  const country = standaloneCountry ?? advendioCountry;

  const consumerSystemAdvertiserId = searchData.get('consumerSystemAdvertiserId');
  const campaignItemId = searchData.get('campaign_item_id');

  return { country, consumerSystemAdvertiserId, campaignItemId, searchData };
};
