import React from 'react';

import Box from '@mui/material/Box';

import { Icon } from 'components/icon';
import { Menu } from 'components/menu';
import { OffsetContainer } from 'components/offset-container';

import { styles } from './styles';

const Header: React.FC = () => {
  return (
    <OffsetContainer>
      <Box sx={styles.headerLayout}>
        <Box display="flex" alignItems="center" height={107}>
          <Icon name="dpg-header-logo" width={151.5} />
        </Box>
        <Menu />
      </Box>
    </OffsetContainer>
  );
};

export { Header };
