import { useContext, useMemo } from 'react';

import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

import { AppContext } from 'components/app-provider';

import { styles } from './styles';
import HeadInfo from './UserDropdown/HeadInfo';
import MenuField from './UserDropdown/MenuField';

export const useMenuItems = () => {
  const {
    keycloak: { logout },
  } = useKeycloak();

  const { user } = useContext(AppContext);

  const { userAlias } = useMemo(() => {
    const firstName = user?.given_name;
    const lastName = user?.family_name;
    if (!firstName || !lastName) {
      return {
        userAlias: '',
        userName: '',
      };
    }
    return {
      userAlias: firstName.charAt(0) + lastName.charAt(0),
      userName: `${firstName} ${lastName}`,
    };
  }, [user?.family_name, user?.given_name]);

  const UserLogoElem = useMemo(
    () => (
      <Typography variant="h5" sx={styles.title}>
        {userAlias}
      </Typography>
    ),
    [userAlias]
  );

  const menuItems = useMemo(
    () => [
      {
        textId: `${userAlias}`,
        customRenderer: (
          <HeadInfo userName={user?.name ?? ''} userEmail={user?.email ?? ''} />
        ),
        defaultExpanded: true,
        sx: styles.customHover,
      },
      {
        textId: 'nav.dropdown.logout',
        sx: styles.customMenuItem,
        onClick: () => {
          logout();
        },
        customRenderer: <MenuField icon="logout" textId="logout.link" />,
      },
    ],
    [logout]
  );

  return { UserLogoElem, menuItems };
};
