import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback } from 'react';

import { CheckCircle, Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import { themeColors } from 'theme';

import { styles } from './styles';

const variantColor = {
  success: themeColors.greenPrimary,
  error: themeColors.redPrimary,
  warning: themeColors.yellowPrimary,
  info: themeColors.purplePrimary,
};

const CustomNotification = forwardRef<
  HTMLDivElement,
  {
    id: string | number;
    variant: 'success' | 'error' | 'warning' | 'info';
    title?: React.ReactElement;
    message?: React.ReactElement;
    button?: {
      label: string;
      clickHandler(id: number): void;
    };
  }
>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);
  return (
    <SnackbarContent
      ref={ref}
      style={{
        width: 400,
      }}
    >
      <Card sx={styles.card} className={`rootCard_${props.variant}`}>
        <Box display="flex">
          <Box display="flex" marginRight={2}>
            <CheckCircle
              className={`icon_${props.variant} icon`}
              sx={{
                '& path': {
                  fill: variantColor[props.variant],
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="100%"
            justifyContent="center"
          >
            {props.title && (
              <Typography variant="subtitle2" sx={styles.title}>
                {props.title}
              </Typography>
            )}
            {props.message && (
              <Box display="flex" alignItems="end" paddingTop="1px">
                <Typography component="span" style={{ fontSize: 14 }}>
                  {props.message}
                </Typography>
              </Box>
            )}
            {props.button && (
              <Box mt={props.title ? 0.5 : 0}>
                <Button
                  disableRipple
                  variant="text"
                  color="error"
                  sx={styles.button}
                  data-testid={`${props.variant}-snackbar-notification-button`}
                  endIcon={<Icon style={{ fontSize: 20 }}>chevron_right</Icon>}
                  onClick={() => props.button?.clickHandler(props.id as number)}
                >
                  {props.button.label}
                </Button>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            ml={1}
            sx={{
              cursor: 'pointer',
            }}
            onClick={handleDismiss}
          >
            <Close
              className="icon"
              sx={{
                '& path': {
                  fill: themeColors.purplePrimary,
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </SnackbarContent>
  );
});
export { CustomNotification };
