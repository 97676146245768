import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';

interface Props {
  icon: IconName;
  textId: string;
}

const MenuField: React.FC<Props> = ({ icon, textId }) => (
  <>
    <ListItemIcon>
      <Icon color={themeColors.purplePrimary} name={icon} />
    </ListItemIcon>
    <Typography noWrap variant="inherit">
      <FormattedMessage id={textId} />
    </Typography>
  </>
);

export default MenuField;
