import axios, { AxiosError, AxiosInstance } from 'axios';
import qs from 'query-string';

export const createApiClient = (baseUrl: string): AxiosInstance => {
  const ApiClient = axios.create({
    baseURL: baseUrl,
    paramsSerializer(params) {
      return qs.stringify(params);
    },
  });

  ApiClient.interceptors.response.use(
    (response) => response,
    (error): Promise<AxiosError> | string | unknown[] => {
      return Promise.reject({
        ...error,
        message: error?.response?.data?.message || 'error.generic',
      });
    }
  );

  return ApiClient;
};
