import React from 'react';

import { SvgIcon } from '@mui/material';

const NotSortedIcon: React.FC = () => (
  <SvgIcon>
    <path d="M7 11l4-4 4 4z" />
    <path d="M7 13l4 4 4-4z" />
  </SvgIcon>
);

export { NotSortedIcon };
