import { TextField as TextFieldBase, TextFieldProps } from 'mui-rff';
import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import InputAdornment from '@mui/material/InputAdornment';

import { getDefaultInputHeight } from './constants';
import { styles } from './styles';

const TextField: React.FC<TextFieldProps> = (props) => {
  const { InputProps, ...rest } = props;

  const updatedProps: TextFieldProps = {
    variant: 'outlined',
    InputProps: InputProps || {
      sx: {
        ...styles.root,
        ...getDefaultInputHeight(props.multiline),
      },
      endAdornment: (
        <InputAdornment position="start">
          <ErrorIcon sx={styles.errorIcon} />
        </InputAdornment>
      ),
    },
    ...rest,
  };

  return <TextFieldBase {...updatedProps} />;
};

export { TextField };
